.tab-container {
  display: flex;
  justify-content: center;
  margin-top: 0px;
}

.tab-menu {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #f3f3f3;
  /* padding: 10px; */
  border-radius: 5px;
  width: fit-content;
}

.tab-button {
  background: none;
  border: none;
  padding: 3px 18px;
  cursor: pointer;
  font-size: 12px;
  font-weight: bold;
  color: #555;
  z-index: 1;
}

.tab-button.active {
  color: #fff;
}

.slider {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 33.33%;
  height: 100%;
  background-color: #186274b2;
  border-radius: 5px;
  transition: all 0.3s ease;
  z-index: 0;
}

.slider.color {
  transform: translateX(0%);
}

.slider.gradient {
  transform: translateX(100%);
}

.slider.image {
  transform: translateX(200%);
}
.slider.box {
  transform: translateX(0%);
}

.slider.card {
  transform: translateX(100%);
}

.slider.bag {
  transform: translateX(200%);
}
@font-face {
  font-family: "Poppins";
  src: url("/src/Poppins-Regular.ttf");
}

.App {
  text-align: center;
  min-height: 100vh;
  background: linear-gradient(#B5B5B5,#EBEBEB 100%); /* Default background */
  background-size: cover;
}
*{
  font-family: "Poppins";
}
.wrapper{
  min-height: 100vh;
}
.model-detail {
  display: flex;
  align-items: start;
}
.model-select{
  width: 100%;
  /* margin: 0 20px; */
  padding: 7px 10px;
border-radius: 5px;
  border: 1px solid rgba(0, 0, 0, 0.288);
}
.model-select option :hover{
  font-size: 200px;
  background-color: red !important;
  fill: #66b5d9 !important;
  color: #66b5d9 !important;
}
select {
  width: 200px;
  background-color: #f5f5f5;
  color: #333;
  border: 1px solid #ccc;
}

.sdf:hover{
  background-color: red !important;
}
.side-menu {
  margin: 20px 10px;
  padding: 20px 0px;
  width: 100%;
  max-width: 80px;
  background-color: #f0f0f0;
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  text-align-last: center;
}

.side-menu button {
  padding: 10px;
  border: none;
  background: none;
  cursor: pointer;
  text-align: left;
  
  transition: color 0.3s;
}

.side-menu button:hover {
  color: #66b5d9;
}
.WrapApp{
  background-color: #ffffff18;
  border-radius: 8px;
  text-align: left;
  padding: 20px;
  margin: 0  0 20px 0;
  display: flex;
  
  align-items: center;
}
@media (max-width:799px){
  .WrapApp{

    display: inline-grid;
  }
  
}
.allbutton{
  width: 100%;
    max-width: 199px;
    float: left;
    padding: 8px;
    border-radius: 2px;
    border: none;
    text-align: -webkit-left;
    cursor: pointer;
}

.firstwrapdiv{
  align-self: flex-start;

}
.custom-dropdown {
  position: relative;
  display: inline-block;
  width: 200px;
  margin-right: 20px;
}

.custom-dropdown select {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background: #fff;
  cursor: pointer;
}
.wrapdiv{
  /* background-color: #ffffff18; */
  /* border-radius: 8px; */
  /* text-align: left; */
  /* padding: 20px; */
  /* margin: 0  0 20px 0; */
  display: flex;
  align-items: center;
}
.firststwrapdiv{
  align-self: flex-start;
  padding: 0 20px;
}

.side-content {
  border-radius: 10px;
  padding: 20px;
  margin: 20px 0;
  /* height:100%;
  min-height: 50%; */
  width: 100%;
  max-width: 200px;
  background-color: #f9f9f9;
  display: flex;
  flex-direction: column;
  
  transition: opacity 0.2s ease-in-out;
}
.dropzone {
  border: 1px dashed #66b5d9;
  padding: 70px 20px;
  margin: 20px 0;
  text-align: center;
  cursor: pointer;
  border-radius: 10px;
}
.custom-button{
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #edeef3 !important;
  border-radius: 10px;
  padding: 5px 15PX;
  text-align: left;
  cursor: POINTER;
  margin: 0 0 10px 0;
}
.custom-button:hover{
  background-color: #dedee4 !important;

}
.custom-button p{
  font-size:10px ;
  margin: 0;
  padding: 0;
}
.custom-button h6{
  margin: 0;
  padding: 0;
  font-size:13px ;
  font-weight: 600;
}
.tab-menu {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.tab-menu button {
  flex: 1;
  margin: 0 0px;
}

.color-tab, .gradient-tab, .image-tab {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.underline {
  border: 1px solid rgba(0, 0, 0, 0.205) !important;
  margin: 5px 5px;
  cursor: pointer;
  font-size: 12px;
  border-radius: 20px;
  padding: 7px;
  border: none;
  background-color: #edeef3;
}
.underline:hover{
/* background-color: #e0e0e0; */
color: #66b5d9;
}

.underline.active{
background-color: #66b5d9;
color: #fff;
}
.underline-flip:hover{
background-color: #e0e0e0;
}

.underline-flip {
  border: 1px solid rgba(0, 0, 0, 0.205) !important;
  border-radius: 5px;
  margin: 5px 5px;
  padding: 5px 5px;
  /* display: flex; */
  font-size: 10px;
  cursor: pointer;
}
.spot-buttons{
  border: 1px solid rgba(0, 0, 0, 0.205) !important;
  border-radius: 5px;
cursor: pointer;
}
.underlineButton{
  /* border: 1px solid rgba(0, 0, 0, 0.205) !important; */
  border-radius: 5px;
  margin: 5px 15px 0;
  text-decoration: none !important;
  
}
.HamBurgButton{
  display: none;
  
}
.scene-container {
  /* flex: 1; */
  width: 70%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.ContainerAPp{
  padding: 20px;

}
@media (max-width:800px){
  .HamBurgButton{
    display: FLEX;
    border: 1px solid rgba(0, 0, 0, 0.205) !important;
    border-radius: 7px;
    margin: 5px 15px 0;
    
    align-self: center;
    
  }
  
}
.home {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 10px;
  /* padding: 20px; */
}

.model-card {
  background-color: #f0f0f0;
  border: 1px solid #ccc;
  border-radius: 8px;
  text-align: center;
  padding: 0px;
}
.model-image{
  width: 100%;
  height: auto;
  border-radius: 8px;
  /* max-width: 150px; */
}
.model-heading{
  text-align: left;
  padding: 0PX 20PX;
  color: #00000093;
  font-size: 14PX;
}
.model-wrap{
  height: calc(170px - 0px);
}
@media (max-width: 1024px) {
  .home {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (max-width: 768px) {
  .home {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media (max-width: 468px) {
  .home {
    grid-template-columns: repeat(1, 1fr);
  }
  .firstwrapdiv{
    display: none;
  }
}
.Wrapmodels{
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 5px;
  padding: 10px;
}
.spotWrap{
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 5px;
  padding: 10px;

}
.model-card-inner {
  background-color: #f0f0f0;
  border: 1px solid #ccc;
  border-radius: 5px;
  text-align: center;
  /* padding: 10px; */
}
.model-image-inner{
  width: 100%;
  height: auto;
  max-width: 120px;
  border-radius: 5px;
}
.model-heading-inner{

  padding: 0;
  margin: 0;
  color: black;
  font-size: 11px;
  font-weight: 100;
}
.model-wrap-inner{
  /* height: calc(85px - 0px); */
}

.tabs-container {
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 20px;
}

.underlineButton {
  background-color: transparent;
  border: none;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #333;
}

.underlineButton.active {
  color: #66b5d9; /* Change background color for active tab */
}

.underlineButton:hover {
  text-decoration: underline;
  color: #66b5d9;
  background-color: none !important;
}
.BackButton {
  border-radius: 5px;
  margin: 5px 14px 0;
  text-decoration: none !important;
  background-color: transparent;
  /* border: 1px solid rgba(0, 0, 0, 0.123) !important; */
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #333;

}

.BackButton:hover {
  text-decoration: underline;
  color: #66b5d9;
  background-color: none !important;
}
.BorderUnderline{
  padding: 10px 0;
  border-bottom: 2px solid rgba(0, 0, 0, 0.09);
}
.matreiWrap{
  display: flex;
    justify-content: center;
    align-items: center;
}
.materialButtonWhite{
  width: 100px;
    height: 70px;
    border-radius: 10px;
    border: none;
    margin: 0 10px 0 0;
    font-size:11px;
    color: #00000073;
    
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: self-end;
  }
  .materialButtonWhite.active {
  border: 2px solid #66b5d9 !important;
}
.materialButtonKraft{
  width: 100px;
    height: 70px;
    border-radius: 10px;
    border: none;
    background-color: #deb37f;
    font-size:11px;
    color: #00000073;
    
    justify-content: center;
    display: flex;
    align-items: self-end;
    text-align: center;
}
.materialButtonKraft.active {
  border: 2px solid #66b5d9 !important;
}
.sizeWrap{
  display: flex;
  justify-content: space-between;
  text-align: left;
  /* padding: 10px 0; */
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
.inputSizes{
  width: 100%;
  max-width: 40px;
  padding: 5px;
  border: 1px solid rgba(0, 0, 0, 0.295);
  border-radius: 5px;
  margin: 3px 10px 10px 0;
}
.inputSizes:focus-visible{
  border: 1px solid #66b5d9 !important;
  outline: none;
}
.applyButton{
  border-radius: 20px;
  padding: 7px;
  border: none;
  background-color: #91848441;

}
.applyButton:active{
color: #66b5d9;
}
@media (max-width:800px){
  .side-content {
    max-width: 200px;
    position: absolute;
    z-index: 1;
    left:100px;
  }
}/* Animations */
@keyframes slideIn {
  from {
    transform: translateY(-100%);
  }
  to {
    transform: translateY(0);
  }
}

/* Menu Styles */
.menu {
  position: relative;
}

.menu-header {
  background-color: #00000079;
  color: #000;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 8rem;
  border-bottom: 2px solid #66b5d9;
}

.linesbetween {
  border-right: 2px solid #66b5d9;
  height: 20px;
  padding-left: 12px;
}

.menu-logo img {
  height: 70px; /* Adjust based on your logo size */
  max-width: 200px;
}

.menu-links,
.menu-mobile-icons {
  display: flex;
  align-items: center;
}

.menu-links a,
.login-button {
  margin-left: 1rem;
  color: #fff;
  text-decoration: none;
  transition: color 0.3s;
  font-size: 16px;
  font-weight: bold;
}

.menu-links a:hover,
.login-button:hover {
  color: #ccc;
}

.menu-icon-button {
  background: none;
  border: none;
  color: #fff;
  cursor: pointer;
}

.menu-icon {
  width: 24px;
  height: 24px;
}

.mobile-menu {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  background-color: #000;
  color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem;
  animation: slideIn 0.3s ease-out;
}

.mobile-menu a {
  margin-top: 1rem;
  color: #fff;
  text-decoration: none;
}

.close-menu-button {
  background: none;
  border: none;
  color: #fff;
  position: absolute;
  top: 1rem;
  right: 1rem;
  cursor: pointer;
}

.close-menu-icon {
  width: 24px;
  height: 24px;
}

/* Media Queries */
@media (max-width: 900px) {
  .menu-header {
    padding: 0.5rem 1rem;
  }

  .menu-links {
    display: none;
  }

  .login-button {
    display: none;
  }

  .menu-mobile-icons {
    display: flex;
  }
}

@media (max-width: 568px) {
  .firstwrapdiv{
    display: none;
  }
}

.edit-menu{
  transition: opacity 0.3s ease-in-out;
}
.background-menu{
  transition: opacity 0.3s ease-in-out;

}

.fade-in {
  opacity: 1;
}

.fade-out {
  opacity: 0;
}
